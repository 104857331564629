.UxFooter {

    width: 100%;
    padding-bottom: 50px;
}

.UxFooterImage {
    width: 100% !important;
    display: block !important
}

.UxFooterImage {
    max-height: 350px;
    width: 100%;
    display: block;
}

.UxFooterLogo {
    margin-top: -100px;
    height: 200px;
    border-radius: 100%;

}

.UxFooter p {
    padding: 50px;
    max-width: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #444
}

.UxFooter a {
    margin-top: 20px;
    padding: 15px;
    border-radius: 50px;
    background: #fff;
    display: inline-block;
    margin: 20px;
    color: #fff;
    background: #447BBE;
    border: 1px solid #fff;
}

.UxFooter p {
    padding: 5px;
}

.UxFooter p img {
    max-width: 500px
}