.UxLandingPageComponent {

    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    margin-top: 200px;
    padding: 50px;
    text-align: center;
    border-radius: 5px;

}

.UxLandingPageLogo {
    margin-top: -150px;
    border-radius: 100%;
    width: 200px;
}

.UxLandingPageDivider {
    border-bottom: 1px solid #ddd;
    margin: 30px 0 30px 0
}