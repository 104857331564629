.UxBanner {

    width: 100%;
}

.UxBannerImage {
    width: 100% !important;
    display: block !important
}

.UxBannerImage {
    max-height: 350px;
    width: 100%;
    display: block;
}

.UxBannerLogo {
    margin-top: -100px;
    height: 200px;
    border-radius: 100%;

}

.UxBanner p {
    padding: 50px;
    max-width: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #444
}